<template>
    <section class="page-visit header-breadcrumb">
        <b-breadcrumb :items="items"></b-breadcrumb>
    </section>
</template>
<script>
export default {
    name: "Breadcrumbs",
    data() {
        return {
            paths: [],
            routeName: "Bankstellen",
            routePath: "",
        };
    },
    computed: {
        items() {
            const items = [];
            const bcrumbs = this.$store.getters["breadcrumbs/getBreadcrumbsRoutes"];
            let item = { text: "Home", to: "/" };
            items.push(item);
            bcrumbs.forEach((bcrumb) => {
                item = { text: bcrumb.name, to: bcrumb.route_link };
                items.push(item);
            });
            const curr = this.$store.getters["breadcrumbs/getBreadcrumbsCurrent"];
            item = { text: curr, active: true };
            items.push(item);
            return items;
        },
    },
    created() {
        this.routeName = this.$route.name;
        this.routePath = this.$route.path;
    },
};
</script>